import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataAboutUs } from "../../states/slices/about-us";

export default function DaftarProgram() {
  const dispatch = useDispatch();
  const aboutus = useSelector((state) => state.aboutus);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (aboutus.status === "idle") {
      dispatch(getDataAboutUs());
    }
  }, [dispatch, aboutus.status]);
  return (
    <div className="main-content px-2 py-2">
      <div className="d-flex flex-column w-100 h-100">
        <div
          className="rounded p-3"
          style={{
            backgroundColor: "#ffffff",
            width: "100%",
            boxShadow: "0 0 5px rgba(0, 0, 0, .10)",
          }}
        ></div>
      </div>
    </div>
  );
}
