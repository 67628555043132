import Swal from 'sweetalert2';

const handleImageError = (err) => {
    if (err === "size") {
        Swal.fire({
            icon: 'warning',
            title: 'Oops',
            text: 'Image yang dipilih lebih dari 3MB',
            width: 300,
            showConfirmButton: false,
            timer: 2000
        });
    } else if (err === "type") {
        Swal.fire({
            icon: 'warning',
            title: 'Oops',
            text: 'Image yang dipilih bukan png | jpg | jpeg',
            width: 300,
            showConfirmButton: false,
            timer: 2000
        });
    } else {
        Swal.fire({
            icon: 'error',
            title: 'Internal Server Error',
            width: 300,
            showConfirmButton: false,
            timer: 1500
        });
    }
}

export default handleImageError;