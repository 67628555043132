import React, { useEffect, useState } from "react";
import { Nav, Navbar, Image, Offcanvas, ListGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  accessArticle,
  accessAboutUs,
  accessContactUs,
  accessEvents,
  accessPartnership,
  // accessDaftarProgram,
  accessJobOrder,
} from "../../const/access";
import NavAccess from "../NavAccess";

export default function HeaderSidebar() {
  const [role, setRole] = useState(null);

  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  useEffect(() => {
    const fetchData = () => {
      let { role } = localStorage.getItem("auth")
        ? JSON.parse(localStorage.getItem("auth"))
        : {};

      setRole(role);
    };
    fetchData();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/signin";
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navList = (
    <ListGroup variant="flush">
      <ListGroup.Item
        action
        className={`border-0 ${
          splitLocation[1] === "contact-us" ? "ka-active" : ""
        }`}
      >
        <NavAccess role={role} roles={accessContactUs.show} to="/contact-us">
          <div className="d-flex flex-row align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              style={{ width: "1.4rem", height: "1.4rem" }}
            >
              <path
                strokeLinecap="round"
                d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
              />
            </svg>
            <div className="ms-3">Contact Us</div>
          </div>
        </NavAccess>
      </ListGroup.Item>
      <ListGroup.Item
        action
        className={`border-0 ${
          splitLocation[1] === "about-us" ? "ka-active" : ""
        }`}
      >
        <NavAccess role={role} roles={accessAboutUs.show} to="/about-us">
          <div className="d-flex flex-row align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              style={{ width: "1.4rem", height: "1.4rem" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
            <div className="ms-3">About Us</div>
          </div>
        </NavAccess>
      </ListGroup.Item>
      <ListGroup.Item
        action
        className={`border-0 ${
          splitLocation[1] === "article" ? "ka-active" : ""
        }`}
      >
        <NavAccess role={role} roles={accessArticle.show} to="/article">
          <div className="d-flex flex-row align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              style={{ width: "1.4rem", height: "1.4rem" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
              />
            </svg>
            <div className="ms-3">Article</div>
          </div>
        </NavAccess>
      </ListGroup.Item>
      <ListGroup.Item
        action
        className={`border-0 ${
          splitLocation[1] === "event" ? "ka-active" : ""
        }`}
      >
        <NavAccess role={role} roles={accessEvents.show} to="/event">
          <div className="d-flex flex-row align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              style={{ width: "1.4rem", height: "1.4rem" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
              />
            </svg>
            <div className="ms-3">Event</div>
          </div>
        </NavAccess>
      </ListGroup.Item>
      <ListGroup.Item
        action
        className={`border-0 ${
          splitLocation[1] === "job-offering" ? "ka-active" : ""
        }`}
      >
        <NavAccess role={role} roles={accessJobOrder.show} to="/job-offering">
          <div className="d-flex flex-row align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              style={{ width: "1.4rem", height: "1.4rem" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
              />
            </svg>
            <div className="ms-3">Job Offering</div>
          </div>
        </NavAccess>
      </ListGroup.Item>
      <ListGroup.Item
        action
        className={`border-0 ${
          splitLocation[1] === "partnership" ? "ka-active" : ""
        }`}
      >
        <NavAccess role={role} roles={accessPartnership.show} to="/partnership">
          <div className="d-flex flex-row align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              style={{ width: "1.4rem", height: "1.4rem" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
              />
            </svg>
            <div className="ms-3">Partnership</div>
          </div>
        </NavAccess>
      </ListGroup.Item>
      {/* <ListGroup.Item
        action
        className={`border-0 ${
          splitLocation[1] === "program-list" ? "ka-active" : ""
        }`}
      >
        <NavAccess
          role={role}
          roles={accessDaftarProgram.show}
          to="/program-list"
        >
          <div className="d-flex flex-row align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              style={{ width: "1.4rem", height: "1.4rem" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
              />
            </svg>
            <div className="ms-3">Program List</div>
          </div>
        </NavAccess>
      </ListGroup.Item> */}
    </ListGroup>
  );

  return (
    <>
      <Navbar
        className="fixed-top shadow-sm"
        style={{
          backgroundColor: "#ffffff",
          minHeight: "60px",
          maxHeight: "60px",
        }}
      >
        <div
          style={{ cursor: "pointer" }}
          className="d-flex justify-content-center align-items-center ps-3 d-md-none"
          onClick={handleShow}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#34364a"
            height="1.5em"
            viewBox="0 0 448 512"
          >
            <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
          </svg>
        </div>
        <Navbar.Brand className="d-flex justify-content-center align-items-center ps-3">
          <Image src="/logo.svg" alt="logo" width="160" height="40" fluid />
        </Navbar.Brand>
        <Nav className="w-100 justify-content-end pe-3">
          <div style={{ cursor: "pointer" }} onClick={handleLogout}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#34364a"
              height="1.5em"
              viewBox="0 0 512 512"
            >
              <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V256c0 17.7 14.3 32 32 32s32-14.3 32-32V32zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z" />
            </svg>
          </div>
        </Nav>
      </Navbar>
      <aside
        className="d-none d-md-block"
        style={{
          bottom: "0",
          float: "none",
          left: "none",
          top: "0",
          position: "fixed",
          height: "100vh",
          overflowY: "hidden",
          maxWidth: "250px",
        }}
      >
        <div
          style={{
            height: "100vh",
            marginTop: "calc(40px + 1.3rem)",
            backgroundColor: "#ffffff",
            width: "250px",
          }}
        >
          <div style={{ width: "100%", height: "100vh" }}>{navList}</div>
        </div>
      </aside>

      <Offcanvas show={show} onHide={handleClose} style={{ width: "250px" }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="d-flex justify-content-center align-items-center">
            <Image src="/logo.svg" alt="logo" width="150" height="40" fluid />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">{navList}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
