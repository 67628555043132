import React from "react";
import { Button, Form } from "react-bootstrap";

import CButton from "../../components/Button";
import TextInputWithLabel from "../../components/TextInputWithLabel";

export default function CForm({
  formUpdate,
  handleChange,
  handleSubmit,
  handleBack,
  isLoading,
}) {
  return (
    <Form>
      <input type="hidden" value={formUpdate.id} />
      <TextInputWithLabel
        label="Nama"
        type="text"
        name="name"
        value={formUpdate.name}
        placeholder="Enter Nama"
        onChange={handleChange}
        autoComplete="on"
      />
      <TextInputWithLabel
        label="Email"
        type="email"
        name="current_email"
        value={formUpdate.current_email}
        placeholder="Enter email"
        onChange={handleChange}
        autoComplete="on"
      />
      <TextInputWithLabel
        label="New Password"
        type="password"
        name="new_password"
        value={formUpdate.new_password}
        placeholder="Enter password"
        onChange={handleChange}
        autoComplete="new-password"
      />
      <TextInputWithLabel
        label="Confirm New Password"
        type="password"
        name="confirm_new_password"
        value={formUpdate.confirm_new_password}
        placeholder="Enter confirm password"
        onChange={handleChange}
        autoComplete="new-password"
      />
      <Button
        variant="link"
        className="ka-secondary px-0 link-offset-1"
        onClick={handleBack}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2.5}
          stroke="currentColor"
          style={{
            width: "1.1rem",
            height: "1.1rem",
          }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
          />
        </svg>
        <span className="ms-1">Back to Sign in</span>
      </Button>
      <CButton
        loading={isLoading}
        disabled={isLoading}
        action={handleSubmit}
        variant="primary"
        className="d-flex flex-row align-items-center justify-content-center float-end"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          style={{
            width: "1rem",
            height: "1rem",
          }}
        >
          <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
        </svg>

        <span className="ms-2">Submit</span>
      </CButton>
    </Form>
  );
}
