import React from 'react';
import { Button } from 'react-bootstrap';

function CButton({
    children,
    action,
    variant,
    size,
    loading,
    disabled,
    className,
    style
}) {
    return (
        <Button
            className={`${variant === 'primary' ? 'ka-primary-bg' : 'ka-secondary-bg'} ${className}`}
            onClick={action}
            size={size}
            disabled={disabled}
            style={style}
        >
            {loading ? (<div className='d-flex flex-row align-items-center'><span className="spinner-border spinner-border-sm me-2"></span>
                <span role="status">Loading...</span></div>) : children}
        </Button>
    );
}

export default CButton;

