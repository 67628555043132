import React from 'react';
import { Form } from 'react-bootstrap';

function TextAreaWithLabel({
    label,
    name,
    value,
    onChange,
    placeholder,
    height
}) {
    return (
        <Form.Group className='mb-3'>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                as="textarea"
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                style={{ height: height ? '' : '100px' }}
            />
        </Form.Group>
    );
}

export default TextAreaWithLabel;