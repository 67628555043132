import React from "react";
import { Form, Row, Col, Image } from "react-bootstrap";

import TextInputWithLabel from "../../components/TextInputWithLabel";
import FileInputWithLabel from "../../components/FileInputWithLabel";
import RichTextWithLabel from "../../components/RichTextWithLabel";

export default function CForm({
  form,
  handleChange,
  handleEditor,
  loading,
  preview,
  content,
}) {
  return (
    <Form>
      <TextInputWithLabel
        label="Title"
        type="text"
        name="title"
        value={form.title}
        placeholder="Masukkan title"
        onChange={handleChange}
      />
      <Row>
        <Col sm="12" md="6">
          <TextInputWithLabel
            label="Tanggal"
            type="datetime-local"
            name="date"
            value={form.date}
            placeholder="Masukkan date"
            onChange={handleChange}
          />
          <FileInputWithLabel
            label="Image"
            name="event"
            onChange={handleChange}
          />
        </Col>
        <Col
          sm="12"
          md="6"
          className="d-flex align-items-center justify-content-center"
        >
          {loading ? (
            <div className="d-flex flex-column align-items-center mt-4">
              <span className="spinner-border spinner-border-sm me-2"></span>
              <span role="status">Loading Image...</span>
            </div>
          ) : preview ? (
            <div className="m-auto thumbnail">
              <Image src={preview} className="bg-body-tertiary" thumbnail />
            </div>
          ) : (
            <div className="m-auto thumbnail">
              <Image
                src={"/no-thumbnail.svg"}
                className="bg-body-tertiary"
                thumbnail
              />
            </div>
          )}
        </Col>
      </Row>
      <RichTextWithLabel
        label="Content"
        placeholder="Masukkan content event"
        onChange={handleEditor}
        defaultValue={form.content}
        height="130"
      />
    </Form>
  );
}
