import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../Button";

export default function CModal({
  children,
  title,
  size,
  loading,
  disabled,
  showModal,
  handleCloseModal,
  handleSubmit,
}) {
  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      size={size}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="ka-secondary">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={disabled}
          loading={loading}
          action={handleSubmit}
          className="d-flex flex-row align-items-center justify-content-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            style={{
              width: "1rem",
              height: "1rem",
            }}
          >
            <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
          </svg>
          <span className="ms-2">Submit</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
