import React from "react";
import { Form } from "react-bootstrap";

import Button from "../../components/Button";
import TextInputWithLabel from "../../components/TextInputWithLabel";

export default function CForm({ form, handleChange, handleSubmit, isLoading }) {
  return (
    <Form>
      <TextInputWithLabel
        label="Email"
        type="email"
        name="email"
        value={form.email}
        placeholder="Enter email"
        onChange={handleChange}
        autoComplete="on"
      />
      <TextInputWithLabel
        label="Password"
        type="password"
        name="password"
        value={form.password}
        placeholder="Enter password"
        onChange={handleChange}
        autoComplete="new-password"
      />

      <Button
        loading={isLoading}
        disabled={isLoading}
        action={handleSubmit}
        variant="primary"
        className="d-flex flex-row align-items-center justify-content-center float-end"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          style={{
            width: "1rem",
            height: "1rem",
          }}
        >
          <path
            fillRule="evenodd"
            d="M3 4.25A2.25 2.25 0 015.25 2h5.5A2.25 2.25 0 0113 4.25v2a.75.75 0 01-1.5 0v-2a.75.75 0 00-.75-.75h-5.5a.75.75 0 00-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 00.75-.75v-2a.75.75 0 011.5 0v2A2.25 2.25 0 0110.75 18h-5.5A2.25 2.25 0 013 15.75V4.25z"
            clipRule="evenodd"
          />
          <path
            fillRule="evenodd"
            d="M6 10a.75.75 0 01.75-.75h9.546l-1.048-.943a.75.75 0 111.004-1.114l2.5 2.25a.75.75 0 010 1.114l-2.5 2.25a.75.75 0 11-1.004-1.114l1.048-.943H6.75A.75.75 0 016 10z"
            clipRule="evenodd"
          />
        </svg>

        <span className="ms-2">Sign In</span>
      </Button>
    </Form>
  );
}
