import React from "react";
import { Form } from "react-bootstrap";
import SunEditor from "suneditor-react";
import plugins from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";

function RichTextWithLabel({
  label,
  name,
  value,
  onChange,
  placeholder,
  defaultValue,
  height,
}) {
  const options = {
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize"],
      ["paragraphStyle", "blockquote"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor", "textStyle"],
      ["removeFormat"], // Line break
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      ["link", "image", "video", "audio" /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
      /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
      ["fullScreen", "showBlocks", "codeView"],
      ["preview", "print", "save"],
    ],
    plugins: plugins,
  };

  return (
    <Form.Group className="mb-3">
      <Form.Label>{label}</Form.Label>
      <SunEditor
        setAllPlugins={false}
        setOptions={options}
        onChange={onChange}
        placeholder={placeholder}
        setDefaultStyle="font-family: Segoe UI; font-size: 15px;"
        height={height}
        defaultValue={defaultValue}
      />
    </Form.Group>
  );
}

export default RichTextWithLabel;
