import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData } from "../../../utils/fetch";

export const getDataAboutUs = createAsyncThunk(
  "about-us/getData",
  async (args, { rejectWithValue }) => {
    try {
      const result = await getData(`/cms/about-us`);
      return result.data.data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const aboutUsSlice = createSlice({
  name: "about-us",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDataAboutUs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDataAboutUs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getDataAboutUs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default aboutUsSlice.reducer;
