import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataAboutUs } from "../../states/slices/about-us";
import { putData } from "../../utils/fetch";
import Swal from "sweetalert2";
import Form from "./form";

export default function AboutUs() {
  const dispatch = useDispatch();
  const aboutus = useSelector((state) => state.aboutus);
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (aboutus.status === "idle") {
      dispatch(getDataAboutUs());
    }
  }, [dispatch, aboutus.status]);

  const handleEditor = (content) => {
    setContent(content);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      let payload = {
        id: aboutus.data[0]._id,
        content: "",
      };

      if (content === "") {
        payload.content = aboutus.data[0].content;
      } else {
        payload.content = content === "<p><br></p>" ? "" : content;
      }

      const res = await putData(`/cms/about-us`, payload);

      if (res.status === 200 || res.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Saved",
          text: "Data tersimpan",
          width: 300,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(getDataAboutUs());
      }
      setIsLoading(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error?.response?.data?.msg ?? "Internal Server Error",
        width: 300,
        showConfirmButton: false,
      });
      setIsLoading(false);
    }
  };

  return (
    <div className="main-content px-2 py-2">
      <div
        className="rounded p-3"
        style={{
          backgroundColor: "#ffffff",
          width: "100%",
          boxShadow: "0 0 5px rgba(0, 0, 0, .10)",
        }}
      >
        <h3 className="border-bottom ka-secondary pb-2 mb-3 fw-bold">
          About Us
        </h3>
        {aboutus.status === "succeeded" ? (
          <Form
            defaultValue={aboutus.data[0].content}
            contentId={aboutus.data[0]._id}
            handleSubmit={handleSubmit}
            handleEditor={handleEditor}
            isLoading={isLoading}
          />
        ) : (
          "Loading..."
        )}
      </div>
    </div>
  );
}
