import React from "react";
import { Form } from "react-bootstrap";

import TextInputWithLabel from "../../components/TextInputWithLabel";
import TextAreaWithLabel from "../../components/TextAreaWithLabel";
import Button from "../../components/Button";

export default function CForm({ form, handleChange, handleSubmit, isLoading }) {
  return (
    <Form>
      <Form.Control name="id" value={form.id} onChange={handleChange} hidden />
      <TextInputWithLabel
        label="Email"
        type="text"
        name="email"
        value={form.email}
        placeholder="Masukkan email"
        onChange={handleChange}
      />
      <TextInputWithLabel
        label="No. WA"
        type="text"
        name="whatsapp"
        value={form.whatsapp}
        placeholder="Masukkan no. WA"
        onChange={handleChange}
      />
      <TextInputWithLabel
        label="Facebook"
        type="text"
        name="facebook"
        value={form.facebook}
        placeholder="Masukkan facebook"
        onChange={handleChange}
      />
      <TextInputWithLabel
        label="Instagram"
        type="text"
        name="instagram"
        value={form.instagram}
        placeholder="Masukkan instagram"
        onChange={handleChange}
      />
      <TextAreaWithLabel
        label="Address"
        name="address"
        value={form.address}
        placeholder="Masukkan address"
        onChange={handleChange}
        height="50px"
      />
      <div className="border-bottom mt-4 mb-3"></div>
      <TextInputWithLabel
        label="Latitude"
        type="text"
        name="latitude"
        value={form.latitude}
        placeholder="Masukkan latitude"
        onChange={handleChange}
      />
      <TextInputWithLabel
        label="Longitude"
        type="text"
        name="longitude"
        value={form.longitude}
        placeholder="Masukkan longitude"
        onChange={handleChange}
      />
      <div className="border-top pt-3 d-flex justify-content-end align-items-center">
        <Button
          variant="primary"
          loading={isLoading}
          action={handleSubmit}
          className="d-flex flex-row align-items-center justify-content-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            style={{
              width: "1rem",
              height: "1rem",
            }}
          >
            <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
          </svg>
          <span className="ms-2">Submit</span>
        </Button>
      </div>
    </Form>
  );
}
