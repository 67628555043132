import React from "react";
import { Form } from "react-bootstrap";
import RichTextWithLabel from "../../components/RichTextWithLabel";
import Button from "../../components/Button";

export default function CForm({
  handleSubmit,
  isLoading,
  handleEditor,
  defaultValue,
}) {
  return (
    <Form>
      <RichTextWithLabel
        label="Content"
        placeholder="Masukkan content about us"
        onChange={handleEditor}
        defaultValue={defaultValue}
        height="360"
      />
      <div className="border-top pt-3 d-flex justify-content-end align-items-center">
        <Button
          variant="primary"
          className="d-flex flex-row align-items-center justify-content-center"
          loading={isLoading}
          action={handleSubmit}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            style={{
              width: "1rem",
              height: "1rem",
            }}
          >
            <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
          </svg>
          <span className="ms-2">Submit</span>
        </Button>
      </div>
    </Form>
  );
}
