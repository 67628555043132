export const accessArticle = {
  show: ["admin"],
  add: ["admin"],
  edit: ["admin"],
  delete: ["admin"],
};

export const accessAboutUs = {
  show: ["admin"],
  add: ["admin"],
  edit: ["admin"],
  delete: ["admin"],
};

export const accessContactUs = {
  show: ["admin"],
  add: ["admin"],
  edit: ["admin"],
  delete: ["admin"],
};

export const accessEvents = {
  show: ["admin"],
  add: ["admin"],
  edit: ["admin"],
  delete: ["admin"],
};

export const accessPartnership = {
  show: ["admin"],
  add: ["admin"],
  edit: ["admin"],
  delete: ["admin"],
};

export const accessDaftarProgram = {
  show: ["admin"],
  add: ["admin"],
  edit: ["admin"],
  delete: ["admin"],
};

export const accessJobOrder = {
  show: ["admin"],
  add: ["admin"],
  edit: ["admin"],
  delete: ["admin"],
};
