import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getData } from '../../../utils/fetch';

export const getDataContactUs = createAsyncThunk("contact-us/getData", async (args, { rejectWithValue }) => {
    try {
        const result = await getData(`/cms/contact-us`);
        return result.data.data;
    } catch (error) {
        rejectWithValue(error.response.data);
    }
})

const contactUsSlice = createSlice({
    name: "contact-us",
    initialState: {
        data: [],
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDataContactUs.pending, (state, action) => {
            state.status = 'loading';
        })
            .addCase(getDataContactUs.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(getDataContactUs.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export default contactUsSlice.reducer;