import axios from 'axios';
import handleError from './handleError';
import { config } from '../configs';

export async function getData(url, params) {
    try {
        const { token } = localStorage.getItem('auth')
            ? JSON.parse(localStorage.getItem('auth'))
            : {};

        return await axios.get(`${config.base_url_api}${url}`, {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (err) {
        return handleError(err);
    }
}

export async function postData(url, payload, formData = false) {
    try {
        const { token } = localStorage.getItem('auth')
            ? JSON.parse(localStorage.getItem('auth'))
            : {};

        return await axios.post(`${config.base_url_api}${url}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': formData ? 'multipart/form-data' : 'application/json',
            },
        });
    } catch (err) {
        return handleError(err);
    }
}

export async function putData(url, payload, formData = false) {
    try {
        const { token } = localStorage.getItem('auth')
            ? JSON.parse(localStorage.getItem('auth'))
            : {};
        return await axios.put(`${config.base_url_api}${url}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': formData ? 'multipart/form-data' : 'application/json',
            },
        });
    } catch (err) {
        return handleError(err);
    }
}

export async function deleteData(url) {
    try {
        const { token } = localStorage.getItem('auth')
            ? JSON.parse(localStorage.getItem('auth'))
            : {};
        return await axios.delete(`${config.base_url_api}${url}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (err) {
        return handleError(err);
    }
}