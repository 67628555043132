import React, { useState } from "react";
import { Card, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import Form from "./form";
import UpdateForm from "./updateForm";
import { postData, putData } from "../../utils/fetch";
import { useDispatch } from "react-redux";
import { userLogin } from "../../states/auth/actions";

export default function PageSignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [formUpdate, setFormUpdate] = useState({
    id: "",
    name: "",
    current_email: "",
    new_password: "",
    confirm_new_password: "",
  });

  const [showForm, setshowForm] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleChangeUpdateForm = (e) => {
    setFormUpdate({ ...formUpdate, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const res = await postData("/cms/auth/signin", form);

      setIsLoading(false);

      if (Object.keys(res.data.data).length > 3) {
        dispatch(
          userLogin(
            res.data.data.token,
            res.data.data.role,
            res.data.data.email,
            res.data.data.refreshToken
          )
        );
        navigate("/about-us");
      } else {
        Swal.fire({
          icon: "warning",
          title: "Perhatian",
          text: "Silahkan update password anda sebelum login!",
          width: 300,
          showConfirmButton: false,
        });
        setFormUpdate({
          id: res.data.data.id,
          name: res.data.data.name,
          current_email: res.data.data.email,
          new_password: "",
          confirm_new_password: "",
        });
        setshowForm(false);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error?.response?.data?.msg ?? "Internal Server Error",
        width: 300,
        showConfirmButton: false,
      });
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    if (
      formUpdate.name !== "" &&
      formUpdate.current_email !== "" &&
      formUpdate.new_password !== "" &&
      formUpdate.confirm_new_password !== ""
    ) {
      if (formUpdate.new_password === formUpdate.confirm_new_password) {
        if (formUpdate.new_password !== form.password) {
          setIsLoading(true);
          try {
            const payload = {
              name: formUpdate.name,
              email: formUpdate.current_email,
              password: formUpdate.new_password,
              confirmPassword: formUpdate.confirm_new_password,
            };

            const res = await putData(`/cms/users/${formUpdate.id}`, payload);

            if (res.status === 200) {
              setFormUpdate({
                id: "",
                name: "",
                current_email: "",
                new_password: "",
                confirm_new_password: "",
              });
              setForm({ email: "", password: "" });
              setshowForm(true);
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Data berhasil diupdate. Silahkan login ulang dengan password baru anda!",
                width: 300,
                showConfirmButton: false,
              });
            }
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error?.response?.data?.msg ?? "Internal Server Error",
              width: 300,
              showConfirmButton: false,
            });
          }
          setIsLoading(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Password masih sama dengan yang sebelumnya. Silahkan gunakan password lain",
            width: 300,
            showConfirmButton: false,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Password dan Confirm Password tidak sama",
          width: 300,
          showConfirmButton: false,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Masih ada field yang belum diisi",
        width: 300,
        showConfirmButton: false,
      });
    }
  };

  const handleBack = () => {
    setshowForm(true);
    setForm({ email: "", password: "" });
    setFormUpdate({
      id: "",
      name: "",
      current_email: "",
      new_password: "",
      confirm_new_password: "",
    });
  };

  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center min-vh-100"
        style={{
          backgroundImage: "url(/hero.webp)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          className="min-vw-100 min-vh-100"
          style={{
            backgroundColor: "rgb(251 146 60/0.6)",
            position: "absolute",
            opacity: "0.6",
          }}
        ></div>
        <Card style={{ width: "20rem" }}>
          <Card.Body className="m-2">
            <div className="w-100 pb-3 d-flex justify-content-center align-items-center">
              <Image src="./logo.svg" width="150" height="50" alt="logo" />
            </div>
            {showForm ? (
              <Form
                form={form}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
              />
            ) : (
              <UpdateForm
                formUpdate={formUpdate}
                handleChange={handleChangeUpdateForm}
                handleSubmit={handleUpdate}
                isLoading={isLoading}
                handleBack={handleBack}
              />
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
