import React from "react";
import { Form } from "react-bootstrap";

function FileInputWithLabel({ label, name, onChange }) {
  return (
    <Form.Group className="mb-3">
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type="file"
        name={name}
        onChange={onChange}
        accept=".jpg,.jpeg,.png"
      />
      <Form.Text className="ka-secondary fw-medium">
        Ukuran maksimal 3MB
      </Form.Text>
    </Form.Group>
  );
}

export default FileInputWithLabel;
