import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { getData, postData, putData, deleteData } from "../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { getDataPartnership } from "../../states/slices/partnership";
import Form from "./form";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Modal from "../../components/Modal";
import handleImageError from "../../utils/handleImageError";

export default function Partnership() {
  const dispatch = useDispatch();
  const partner = useSelector((state) => state.partners);

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState({ status: "add", id: null });
  const [title, setTitle] = useState("Add Partnership");
  const [imageLoading, setImageLoading] = useState(false);
  const [preview, setPreview] = useState("");

  useEffect(() => {
    if (partner.status === "idle") {
      dispatch(getDataPartnership());
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [dispatch, partner.status]);

  const [form, setForm] = useState({
    title: "",
    add_info: "",
    job_offer: "",
  });

  const handleCloseModal = async () => {
    setShowModal(false);
    if (preview !== "" && action.status === "add") {
      try {
        await deleteData(`/cms/images/${form.file}/partners`);
      } catch (error) {}
    } else {
      if (preview !== "" && form.file !== undefined) {
        handleSubmit();
      }
    }
  };
  const handleShowModal = (id) => {
    if (id !== null) {
      getPartnershipById(id);
      setAction({ status: "edit", id: id });
      setTitle("Edit Partnership");
    } else {
      setAction({ status: "add", id: null });
      setTitle("Add Partnership");
      setPreview("");
      setForm({
        title: "",
        add_info: "",
        job_offer: "",
      });

      setShowModal(true);
    }
  };

  const getPartnershipById = async (id) => {
    try {
      setImageLoading(true);

      const res = await getData(`/cms/partners/${id}`);
      setShowModal(true);

      setForm({
        title: res.data.data.title,
        job_offer: res.data.data.image._id,
        add_info: res.data.data.add_info,
      });

      setPreview(res.data.data.image.image);
      setImageLoading(false);
    } catch (error) {}
  };

  const uploadImage = async (file) => {
    let formData = new FormData();
    formData.append("partner", file);
    const res = await postData("/cms/images/partner", formData, true);
    return res;
  };

  const imageError = (e, err) => {
    handleImageError(err);
    if (action.status === "add") {
      setForm({
        ...form,
        file: "",
        [e.target.name]: "",
      });
      e.target.value = null;
      setPreview("");
    }
  };

  const handleChange = async (e) => {
    if (e.target.name === "partner") {
      if (
        e?.target?.files[0]?.type === "image/jpg" ||
        e?.target?.files[0]?.type === "image/png" ||
        e?.target?.files[0]?.type === "image/jpeg"
      ) {
        const size = Math.round(e.target.files[0].size / 1024);

        if (size > 3072) {
          imageError(e, "size");
        } else {
          setImageLoading(true);

          try {
            const res = await uploadImage(e.target.files[0]);

            setForm({
              ...form,
              file: res.data.data._id,
              [e.target.name]: res.data.data.name,
            });

            const image = e.target.files[0];
            setPreview(URL.createObjectURL(image));

            try {
              if (action.status === "add") {
                if (form.file !== undefined) {
                  await deleteData(`/cms/images/${form.file}/partners`);
                }
              } else {
                await deleteData(`/cms/images/${form.flyer}/partners`);
              }
            } catch (error) {}
          } catch (error) {}

          setImageLoading(false);
        }
      } else {
        imageError(e, "type");
      }
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = {
        image: form.file,
        title: form.title,
        add_info: form.add_info,
      };

      let res = {};

      if (action.status === "add") {
        res = await postData("/cms/partners", payload);
      } else {
        res = await putData(`/cms/partners/${action.id}`, payload);
      }

      if (res.status === 200 || res.status === 201) {
        dispatch(getDataPartnership());
        Swal.fire({
          icon: "success",
          title: "Saved",
          text: "Data tersimpan",
          width: 300,
          showConfirmButton: false,
          timer: 1500,
        });
        setShowModal(false);
      }

      setIsLoading(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error?.response?.data?.msg ?? "Internal Server Error",
        width: 300,
        showConfirmButton: false,
      });
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Apa anda yakin?",
      text: "Anda tidak dapat mengembalikan data ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f58634",
      cancelButtonColor: "#815aa4",
      confirmButtonText: "Iya, Hapus",
      cancelButtonText: "Batal",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteData(`/cms/partners/${id}`);

        if (res.status === 200) {
          await deleteData(`/cms/images/${res.data.data.image._id}/partners`);
          dispatch(getDataPartnership());
        }
      }
    });
  };

  return (
    <div className="main-content px-2 py-2">
      <div className="d-flex flex-column w-100 h-100">
        <div
          className="rounded p-3"
          style={{
            backgroundColor: "#ffffff",
            width: "100%",
            boxShadow: "0 0 5px rgba(0, 0, 0, .10)",
          }}
        >
          <h3 className="border-bottom ka-secondary pb-2 mb-3 fw-bold">
            Partnership
          </h3>
          <Button
            variant="primary"
            className="d-flex flex-row align-items-center justify-content-center"
            action={() => {
              handleShowModal(null);
            }}
          >
            <div className="d-flex flex-row align-items-center justify-content-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                style={{ width: "1.4rem", height: "1.4rem" }}
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="mx-2">Add</span>
            </div>
          </Button>
        </div>

        <Modal
          title={title}
          showModal={showModal}
          loading={isLoading}
          handleCloseModal={handleCloseModal}
          handleSubmit={handleSubmit}
          disabled={imageLoading}
        >
          <Form
            form={form}
            loading={imageLoading}
            handleChange={handleChange}
            preview={preview}
          />
        </Modal>

        <div
          className="rounded p-3 mt-2"
          style={{
            backgroundColor: "#ffffff",
            width: "100%",
            boxShadow: "0 0 5px rgba(0, 0, 0, .10)",
          }}
        >
          <Row className="g-3">
            {partner.status === "loading" ? (
              <div>Loading</div>
            ) : partner.status === "succeeded" &&
              partner.data.partners.length > 0 ? (
              partner.data.partners.map((data, index) => (
                <Card
                  key={index}
                  data={data}
                  handleShowModal={handleShowModal}
                  handleDelete={handleDelete}
                  objectFit="contain"
                />
              ))
            ) : (
              <div className="p-2 text-center fw-medium">Data not found</div>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}
