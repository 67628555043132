import axios from 'axios';
import { config } from '../configs';

const handleError = (error) => {
    const originalRequest = error.config;
    if (error.response.data.msg === 'jwt expired') {
        originalRequest._retry = true;
        const session = localStorage.getItem('auth')
            ? JSON.parse(localStorage.getItem('auth'))
            : {};

        return axios
            .get(
                `${config.base_url_api}/cms/refresh-token/${session.refreshToken}/${session.email}`
            )
            .then((res) => {
                localStorage.setItem(
                    'auth',
                    JSON.stringify({
                        ...session,
                        token: res.data.data.token,
                    })
                );
                originalRequest.headers.Authorization = `Bearer ${res.data.data.token}`;

                return axios(originalRequest);
            })
            .catch((err) => {
                window.location.href = '/signin';
                localStorage.removeItem('auth');
            });
    }

    return Promise.reject(error);
};

export default handleError;