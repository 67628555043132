import { Navigate, Route, Routes } from "react-router-dom";
import GuardRoute from "../components/GuardRoute";
import GuestOnlyRoute from "../components/GuestOnlyRoute";
import Navbar from "../components/Navbar";

import Login from "../pages/Signin";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import Article from "../pages/Article";
import Partnership from "../pages/Partnership";
import JobOffering from "../pages/JobOffering";
import Events from "../pages/Event";
import ProgramList from "../pages/ProgramList";

export function AppRoutes() {
  return (
    <Routes>
      <Route
        path="signin"
        element={
          <GuestOnlyRoute>
            <Login />
          </GuestOnlyRoute>
        }
      />
      <Route
        path="/"
        element={
          <>
            <Navbar />
            <GuardRoute />
          </>
        }
      >
        <Route path="about-us/*" element={<AboutUs />} />
        <Route path="contact-us/*" element={<ContactUs />} />
        <Route path="article/*" element={<Article />} />
        <Route path="partnership/*" element={<Partnership />} />
        <Route path="job-offering/*" element={<JobOffering />} />
        <Route path="event/*" element={<Events />} />
        <Route path="program-list/*" element={<ProgramList />} />
        <Route path="" element={<Navigate to="/contact-us" replace={true} />} />
      </Route>
    </Routes>
  );
}
