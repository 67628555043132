import React, { useState, useEffect } from "react";
import { putData } from "../../utils/fetch";
import Form from "./form";
import Swal from "sweetalert2";
import { getData } from "../../utils/fetch";

export default function ContactUs() {
  useEffect(() => {
    // dispatch(getDataContactUs());
    getContactUs();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [form, setForm] = useState({
    id: "",
    email: "",
    whatsapp: "",
    facebook: "",
    instagram: "",
    address: "",
    latitude: "",
    longitude: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const getContactUs = async () => {
    try {
      const res = await getData(`/cms/contact-us`);

      setForm({
        id: res.data.data[0]._id,
        email: res.data.data[0].email,
        whatsapp: res.data.data[0].whatsapp,
        facebook: res.data.data[0].facebook,
        instagram: res.data.data[0].instagram,
        address: res.data.data[0].address,
        latitude: res.data.data[0].latitude,
        longitude: res.data.data[0].longitude,
      });
    } catch (error) {}
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = {
        id: form.id,
        email: form.email,
        whatsapp: form.whatsapp,
        facebook: form.facebook,
        instagram: form.instagram,
        address: form.address,
        latitude: form.latitude,
        longitude: form.longitude,
      };

      const res = await putData(`/cms/contact-us`, payload);

      if (res.status === 200) {
        getContactUs();
        Swal.fire({
          icon: "success",
          title: "Data tersimpan",
          width: 300,
          showConfirmButton: false,
          timer: 1500,
        });
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error?.response?.data?.msg);
      setIsLoading(false);
    }
  };

  return (
    <div className="main-content px-2 py-2">
      <div
        className="rounded p-3"
        style={{
          backgroundColor: "#ffffff",
          width: "100%",
          boxShadow: "0 0 5px rgba(0, 0, 0, .10)",
        }}
      >
        <h3 className="border-bottom ka-secondary pb-2 mb-3 fw-bold">
          Contact Us
        </h3>
        <Form
          form={form}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
