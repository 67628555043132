import React from "react";
import { Form, Image } from "react-bootstrap";

import TextInputWithLabel from "../../components/TextInputWithLabel";
import FileInputWithLabel from "../../components/FileInputWithLabel";

export default function CForm({ form, handleChange, loading, preview }) {
  return (
    <Form>
      <TextInputWithLabel
        label="Title"
        type="text"
        name="title"
        value={form.title}
        placeholder="Masukkan title"
        onChange={handleChange}
      />
      <FileInputWithLabel
        label="Image"
        name="partner"
        onChange={handleChange}
      />

      {loading ? (
        <div className="d-flex flex-column align-items-center mt-4">
          <span className="spinner-border spinner-border-sm me-2"></span>
          <span role="status">Loading Image...</span>
        </div>
      ) : preview ? (
        <div className="m-auto thumbnail">
          <Image src={preview} thumbnail />
        </div>
      ) : (
        <div className="m-auto thumbnail">
          <Image
            src={"/no-thumbnail.svg"}
            className="bg-body-tertiary"
            thumbnail
          />
        </div>
      )}
    </Form>
  );
}
